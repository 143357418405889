
<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col cols="3">
                    <s-select-definition 
                    :def="1390" 
                    label="Grupos" 
                    clearable
                    v-model="TypeCustomerGroup"
                    @change="search"
                     >
                </s-select-definition>
                                   
                </v-col>
                <v-col cols="12">
                    <v-data-table
                         
                        show-select
                        dense 
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        group-by="MtsDescription"
                        >
                        <template v-slot:item.TypeCustomerGroupName="{ item }">							 							   
                                                             
							 <v-chip
                                dark
                                small
                                :color="item.TypeCustomerGroup == 1 ? 'cyan' : 'warning'">                                
                               {{item.TypeCustomerGroupName}} 
                                </v-chip>
                                 
						</template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>

import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';

export default {
		  
		data() {
			return {
                TypeCustomerGroup: 0,
                selected: [],
				processGroup: {},
                items: [],
                headers: [
                 
                { text: "Grupo", value: "TypeCustomerGroupName", width:"70" },
                { text: "ID", value: "LlpID", width:"70" },
               
                { text: "N° Jabas", value: "LlpQuantityJaba" },
                //{ text: "Peso", value: "LlpWeight" },
                            { text: "Peso Neto", value: "LlpWeightOriginalNet" },
                { text: "Categoria", value: "TypeFrozenCategoryName" },
                { text: "Tipo Cultivo", value: "TypeCropName" },
                { text: "Cultivo", value: "TypeCultiveName" },
                { text: "Variedad", value: "VrtName" },
                { text: "Días", value: "dias" },
                { text: "Días Planta", value: "DaysReception" },
                { text: "Días Cosecha", value: "DaysHarvestSchedule" },
                { text: "Estado", value: "estado"},
                
                { text: "Certificaciones", value: "Certifications" },
                { text: "Entrada", value: "MapDateBegin" },
                 
                ]
			}
		},
        methods:{
            search(){
                let model = {};
                model.TypeCustomerGroup = this.TypeCustomerGroup;

                _sMaturationTunnelPalletService.getItemsChamberTable(model, this.$fun.getUserID())
                .then((r) => {
                    console.log("data", r.data);

                    this.items = r.data;
                })
            }
        },
        mounted(){

          this.search();
        }
}
</script>